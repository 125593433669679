<template>
	<page-container :page="page">
		<div class="index">
			<heading-image>
				<hero-image>
					<div class="columns column12">
						<picture v-if="page.headingImage">
							<source
								v-if="page.headingImagePortraitWebp"
								:srcset="page.headingImagePortraitWebp"
								media="(max-width: 500px)"
								type="image/webp"
							/>
							<source
								v-if="page.headingImagePortrait"
								:srcset="page.headingImagePortrait"
								media="(max-width: 500px)"
							/>
							<source :srcset="page.headingImageWebp" type="image/webp" />
							<source :srcset="page.headingImage" />
							<img class="sliderImage" :src="page.headingImage" :alt="page.headingImageAlt" />
						</picture>
					</div>
					<template #intro-content>
						<div class="columns column5">
							<div class="content-wrapper">
								<h1>{{ page.title }}</h1>
								<div v-parse-links v-html="page.content" />
							</div>
						</div>
					</template>
				</hero-image>
			</heading-image>

			<main-content class="desktop-only">
				<div class="columns column12 quote">
					<div v-parse-links v-html="defaults[locale].homepage.quote" />
				</div>
			</main-content>

			<main-content>
				<div class="columns column4">
					<picture v-if="defaults[locale].homepage.extraContentImage">
						<source :srcset="defaults[locale].homepage.extraContentImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.extraContentImage" />
						<img
							:src="defaults[locale].homepage.extraContentImage"
							:alt="defaults[locale].homepage.extraContentImageAlt"
						/>
					</picture>
				</div>
				<div class="columns column8">
					<div class="flex-row index">
						<div class="menu-title" />
						<div class="content-wrapper">
							<div v-parse-links v-html="defaults[locale].homepage.extraContent" />
						</div>
					</div>
					<div class="flex-row keywords">
						<div v-parse-links v-html="defaults[locale].homepage.keywords" />
						<picture v-if="defaults[locale].homepage.keywordsImage">
							<source :srcset="defaults[locale].homepage.keywordsImageWebp" type="image/webp" />
							<source :srcset="defaults[locale].homepage.keywordsImage" />
							<img
								:src="defaults[locale].homepage.keywordsImage"
								:alt="defaults[locale].homepage.keywordsImageAlt"
							/>
						</picture>
					</div>
				</div>
			</main-content>

			<contentblocks :items="page.contentblocks">
				<template #blocks-header>
					<div class="row blocks-header white align-center">
						<div class="columns column12">
							<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
							<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
						</div>
					</div>
				</template>
			</contentblocks>
		</div>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();
await fetchPage();
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 4.4rem;
		font-family: $heading-font-family;
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

.content-wrapper {
	padding: 5px 20px 15px;
	border: 1px solid #000;
	margin: 0 auto;

	p,
	:deep(p) {
		margin: 0;
	}
}

.column5 {
	.content-wrapper {
		max-width: 420px;
	}
}

.quote {
	margin: 40px auto 0;
	max-width: 700px;

	:deep(p) {
		margin: 5px 0;
	}
}

.flex-row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	&.index {
		.content-wrapper {
			width: calc(100% - 110px);
		}
	}

	&.keywords {
		justify-content: flex-end;
		align-items: flex-end;
		align-self: flex-end;

		:deep(p) {
			margin: 20px 0;
			padding: 0 20px;
			font-size: 12px;
			line-height: 14px;
		}
	}
}

.column8 {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;

	.flex-row {
		max-width: 800px;
	}
}

.menu-title {
	background: url('~/assets/images/menu-sideways.png') no-repeat center center;
	background-size: 47px;
	width: 47px;
	height: 182px;
	display: block;
}

@media (max-width: 1080px) {
	.desktop-only {
		display: none;
	}

	.hero-image {
		.column5,
		.column6,
		.column12 {
			padding: 0;
		}
	}

	.menu-title {
		display: none;
	}

	.content-wrapper {
		border: none;
		background: #fff;
		width: 80%;
		max-width: 600px;
		margin: -200px auto 100px;
	}

	.column5 {
		.content-wrapper {
			margin: -60px 0 60px 18%;
		}
	}

	.flex-row {
		&.keywords {
			justify-content: center;
			align-items: center;
			flex-flow: column-reverse wrap;
			width: 100%;

			:deep(div) {
				margin-top: -100px;
				background: #fff;
				position: relative;
				z-index: 2;
			}

			:deep(p) {
				padding: 5px !important;
				margin: 0 !important;
			}

			img,
			picture {
				position: relative;
				z-index: 1;
			}
		}

		&.index {
			.content-wrapper {
				width: calc(100% - 60px);
			}
		}
	}
}
</style>
